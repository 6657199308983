<template>
  <svg
      :width="width"
      :height="height"
      viewBox="0 0 33 33"
      :fill="color"
      xmlns="http://www.w3.org/2000/svg"
      :style="{ fontWeight: fontWeight }"
  >
    <path d="M25.6196 14.142C25.2626 14.142 24.9211 14.2092 24.6062 14.3304C24.258 13.1624 23.1746 12.3081 21.8949 12.3081C21.5268 12.3081 21.1753 12.3795 20.8524 12.508C20.4761 11.3869 19.4163 10.5766 18.1701 10.5766C17.8572 10.5766 17.5562 10.6284 17.2746 10.7227V7.72761C17.2746 6.16765 16.0054 4.89844 14.4454 4.89844C12.8854 4.89844 11.6162 6.16758 11.6162 7.72761V19.9649L10.5112 18.3247L10.4856 18.2928C9.4027 16.9467 7.49766 16.6476 6.05436 17.5971C5.29968 18.0935 4.78838 18.8552 4.61468 19.7416C4.4422 20.6218 4.62434 21.5137 5.12759 22.2551L10.0522 30.0177L10.0716 30.0471C11.3416 31.8962 13.4389 33.0001 15.6821 33.0001H21.209C25.2011 33.0001 28.4488 29.7523 28.4488 25.7602V16.9713C28.4488 15.4112 27.1796 14.142 25.6196 14.142ZM26.5152 25.7602C26.5152 28.6862 24.1348 31.0665 21.209 31.0665H15.6821C14.0817 31.0665 12.5852 30.2818 11.6751 28.9664L6.75175 21.2058L6.73235 21.1763C6.51746 20.8635 6.43934 20.486 6.5123 20.1136C6.58533 19.7412 6.80015 19.4211 7.11726 19.2125C7.71371 18.8201 8.49778 18.9353 8.95643 19.4772L13.55 26.2961V7.72767C13.55 7.2339 13.9517 6.8321 14.4456 6.8321C14.9394 6.8321 15.3412 7.23383 15.3412 7.72767V17.3889H17.2748V13.4058C17.2748 12.912 17.6765 12.5102 18.1703 12.5102C18.6642 12.5102 19.0659 12.912 19.0659 13.4058V17.3889H20.9995V15.1372C20.9995 14.6434 21.4012 14.2416 21.8951 14.2416C22.3889 14.2416 22.7906 14.6434 22.7906 15.1372V17.3889H24.7242V17.0475V16.9712C24.7242 16.4774 25.126 16.0756 25.6198 16.0756C26.1137 16.0756 26.5154 16.4773 26.5154 16.9712V25.7602H26.5152Z" />
    <path d="M15.3582 0H13.4246V2.6748H15.3582V0Z" />
    <path d="M18.4001 1.1417L16.5984 3.11865L18.0275 4.42112L19.8293 2.44417L18.4001 1.1417Z" />
    <path d="M21.3081 5.33532L18.6448 5.58301L18.8238 7.50829L21.4871 7.2606L21.3081 5.33532Z" />
    <path d="M10.3234 1.14089L8.89429 2.44336L10.696 4.42031L12.1252 3.11784L10.3234 1.14089Z" />
    <path d="M7.41538 5.33595L7.23633 7.26123L9.89963 7.50892L10.0787 5.58364L7.41538 5.33595Z" />
  </svg>
</template>

<script>
export default {
  name: 'ClickIcon',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: String,
      default: '33',
    },
    height: {
      type: String,
      default: '33',
    },
    fontWeight: {
      type: String,
      default: 'normal',
    },
  },
};
</script>
