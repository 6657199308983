<template>
  <img
      :src="image"
      alt="Google Play"
      :style="{ height, width }"
      :class="[
      { [`md:h-[${mdHeight}]`]: mdHeight },
      { [`md:w-[${mdWidth}]`]: mdWidth }
    ]"
  />
</template>

<script>
import image from "@/assets/images/get-it-on-google-play.png";

export default {
  name: 'GooglePlayIcon',
  props: {
    width: {
      type: String,
      default: '136px',
    },
    height: {
      type: String,
      default: '48px',
    },
    mdWidth: {
      type: String,
      default: '160px',
    },
    mdHeight: {
      type: String,
      default: '56px',
    },
  },
  data() {
    return {
      image,
    };
  },
};
</script>
