<template>
  <div>

    <LoadingSpinner :is-loading=" isLoadingContactUs"/>

    <div @click="handleContactUsClick" class="social-box cursor-pointer flex flex-col justify-center items-center w-10/12 mx-auto md:w-2/12 lg:text-xl font-bold-700" :style="{ backgroundColor: '#25D366' }">
      <div class="flex items-center w-full px-3 align-center">
        <div class="flex-shrink-0 mb-2">
          <ContactUsIcon />
        </div>
        <div class="flex-grow text-center mb-3">
          <div class="text">تواصل معنا</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import contactUs from "@/mixins/contactUs";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import ContactUsIcon from "@/components/Icons/ContactUsIcon.vue";

export default {
  components: {LoadingSpinner,ContactUsIcon},
  mixins: [contactUs],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    text() {
      switch (this.type.toUpperCase()) {
        case "WHATSAPP":
          return "تواصل معنا";
        default:
          return "تواصل معنا";
      }
    },
  },
  methods: {
  },
};
</script>

<style scoped>
.social-box {
  width: 261px;
  height: 49px;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
}

.text {
  margin-top: 5px;
}

.contact-data {
  margin-top: 5px;
}

.contact-data a {
  color: white;
  text-decoration: none;
}
</style>
