<template>
  <div class="flex flex-col justify-center items-center w-full gap-4 font-bold-700">
    <h1 class="title">
      المنصة الشاملة رقم 1
    </h1>
    <span class="text-blue-custom subtitle  mb-4">
        انضم لاكثر من 500 ألف طالب مع الخطة
    </span>
    <div class="w-full 3xl:w-6/12 flex flex-col mx-auto items-center font-bold-700 text-lg md:text-xl gap-4">
      <div v-if="!isAuth" class="flex flex-col md:flex-row gap-4">
        <router-link class="" :to="{ name: 'Login' }">
          <ButtonCustom :bg-color="'bg-yellow-custom'" className="py-2 px-10 rounded-full text-black-custom"
                        :width="'250px'" :height="'49px'">
            تسجيل دخول
          </ButtonCustom>
        </router-link>
        <router-link class="" :to="{ name: 'Register' }">
          <ButtonCustom :bg-color="'bg-blue-custom'" class="text-white" :width="'250px'" :height="'49px'">
            فتح حساب جديد
          </ButtonCustom>
        </router-link>
      </div>
      <div v-else>
        <router-link class="" :to="{ name: 'studyContent' }">
          <ButtonCustom :bg-color="'bg-yellow-custom'" className="py-2 px-10 rounded-full text-black-custom"
                        :width="'250px'" :height="'49px'">
            المحتوى الدراسى
          </ButtonCustom>
        </router-link>
      </div>
      <a :href="GOOGLE_PLAY_LINK" target="_blank"
         class="w-[315px] md:w-[359px] h-[54px] md:h-[62px] mt-[0px] md:mt-[5px] rounded-10px bg-black text-center flex justify-around align-center
            text-white text-lg md:text-xl">
        <div class="flex gap-2 items-end">
          <span class="font-bold text-18px md:text-[20px]">
          حمل  التطبيق
        </span>
          <ClickIcon/>
        </div>
        <DownloadAppIcon height="48px" width="136px"/>
      </a>
    </div>
    <PhoneLogo class="mt-[1%]"/>
  </div>
</template>

<script>
import ButtonCustom from "@/components/General/ButtonCustom.vue";
import PhoneLogo from "@/components/Landing/PhoneLogo.vue"
import store from "@/store";
import DownloadAppIcon from "@/components/Icons/DownloadAppIcon.vue";
import ClickIcon from "@/components/Icons/ClickIcon.vue";
import {GOOGLE_PLAY_LINK} from "@/helpers/config";

export default {
  name: "MainBanner",
  components: {
    ButtonCustom,
    PhoneLogo,
    DownloadAppIcon,
    ClickIcon,
  },
  data() {
    return {
      GOOGLE_PLAY_LINK
    }
  },
  computed: {
    isAuth() {
      return store.getters['Auth/isAuth'];
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 64px;
}

.subtitle {
  font-size: 30px;
}

@media (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  .subtitle {
    font-size: 18px;
  }
}
</style>
