<template>
    <div>
        <svgicon :data="envelop" :width="width" :height="height" original />
    </div>
</template>

<script>
import envelop from "@/assets/svg/envelop.svg";
export default {
    name: "ContactUsIcon",
    data() {
        return {
            envelop,
        };
    },
    props: {
        width: {
            type: Number,
            default: 32
        },
        height: {
            type: Number,
            default: 32
        }
    }
}

</script>