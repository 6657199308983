<template>
  <div :dir="dir" class="py-4 flex flex-col item-start dynamic-align text-17px max-w-3xl lg:w-4/12 2xl:w-3/12 mx-auto">
    <h4 class="text-blue-custom mb-4 font-bold-500 lg:font-bold-400 dynamic-align">
      {{ text }}
    </h4>
    <div class="flex flex-col justify-start items-start">
      <div v-for="(previousAnswer, index) in previousAnswers" :key="previousAnswer.id" :class="[
        'flex flex-col justify-between text-dark-blue-custom gap-2',
        { 'mt-10': index !== 0 }
      ]">
        <div class="grid grid-cols-4 flex-row gap-2 items-center flex-grow col-span-1">
          <span class="flex gap-1 items-center flex-auto">
            <svgicon :data="Chart" width="17" height="17" original />
            <span>{{ previousAnswer?.score }}%</span>
          </span>

          <span class="mx-[0.5rem] md:mx-[1rem] flex-auto text-center col-span-1">
            {{ previousAnswer?.formatted_time }}
          </span>
          <span v-if="previousAnswer.show" class="flex-auto mx-[1.5rem] md:mx-[2rem] text-right  col-span-2">
            <div class="flex gap-2 text-sm md:text-17px" :title="CheckAnswers">
              <RouterLink :to="{
                name: 'ExamAnswerReview',
                params: {
                  lessonSlug: lessonSlug,
                  exerciseSlug: exerciseSlug,
                  exerciseSessionId: previousAnswer?.id,
                }
              }">
                <TinyArrow :dir="direction" />
                {{ CheckAnswers }}
              </RouterLink>
            </div>
          </span>
        </div>
        <div>
          <span class="dynamic-align flex gap-2 align-center">
            <ClockIcon :width="17" :height="17" :isBlue="true" />
            <span class="">{{ TimeTakenToSolve }}:</span>
            <span>{{ previousAnswer?.time_taken }}</span>
          </span>
        </div>

        <div v-if="previousAnswer.is_time_expired" class="text-red-custom">
          لقد استنفذت الوقت دون أن تقوم بالحل
        </div>
      </div>


    </div>
  </div>
</template>

<script>

import Chart from "@/assets/svg/blue/Chart.svg";
import TinyArrow from "@/components/Icons/TinyArrow.vue";
import routeParamsMixin from "@/mixins/routeParams";
import contentDataMixin from "@/mixins/contentData";
import ClockIcon from "@/components/Icons/ClockIcon.vue";

export default {
  mixins: [routeParamsMixin, contentDataMixin],

  components: { TinyArrow, ClockIcon },
  props: {
    previousAnswers: {
      type: [Object, Array],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    dir: {
      type: String,
      required: true,
    },
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
  },
  data() {
    return {
      Chart,
    };
  },
};
</script>
