import routes from "@/router/routes";


export default {
    props: {
        contentDataProp: {
            type: String,
            required: true
        },
        contentErrorDataProp: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            nextTextArabic: 'التالى',
            nextTextEnglish: 'Next',
            nextOrPreviousTextEnglishDone: 'Finish the lesson',
            nextOrPreviousTextArabicDone: 'إنهاء الدرس',
            previousTextArabic: 'السابق',
            previousTextEnglish: 'Previous',

            startExerciseArabic: 'ابدأ التمرين',
            startExerciseEnglish: 'Start Exercise',
            startExamArabic: 'ابدأ الاختبار',
            startExamEnglish: 'Start Exam',


            alreadyStartedExerciseArabic: 'استكمال التمرين',
            alreadyStartedExerciseEnglish: 'Continue Exercise',
            alreadyStartedExamArabic: 'استكمال الاختبار',
            alreadyStartedExamEnglish: 'Continue Exam',

            numberOfQuestionsArabic: 'عدد الاسئلة',
            numberOfQuestionsEnglish: 'Question Num',
            durationInMinutesArabic: 'الوقت بالدقائق',
            durationInMinutesEnglish: 'Time in Minutes',
            difficultyLevelArabic: 'مستوى الصعوبة',
            difficultyLevelEnglish: 'Difficulty Level',
            PreviousAnswersEnglish: 'Previous Answers',
            PreviousAnswersArabic: 'الإجابات السابقة',

            RetakeQuizEnglish: 'Retake Quiz',
            RetakeQuizArabic: 'اعادة الاختبار',

            subscribeButtonEnglish: 'To subscribe click here',
            subscribeButtonArabic: 'للاشتراك اضغط هنا',

            CheckAnswersEnglish: 'Check Answers',
            CheckAnswersArabic: 'التصحيح',

            TimeTakenToSolveEnglish: 'Time Taken To Solve',
            TimeTakenToSolveArabic: 'وقتك الحل',

            YouDidNotAnswerEnglish: 'You did not answer',
            YouDidNotAnswerArabic: 'لم تقم بالإجابة ',
        }
    },
    computed: {
        contentData() {
            return this.$props[this.contentDataProp] || this.$data[this.contentDataProp] || {};
        },
        contentError() {
            return this.$props[this.contentErrorDataProp] || this.$data[this.contentErrorDataProp] || null;
        },
        contentErrorMessageCode() {
            return this.contentError?.messageCode || null;
        },
        contentErrorMessageData() {
            return this.contentError?.messageData || null;
        },
        isContentNotFoundError() {
            return this.contentErrorMessageCode === 'CONTENT_NOT_FOUND';
        },
        isContentNotEnrolledError() {
            return this.contentErrorMessageCode === 'CONTENT_NOT_ENROLLED';
        },
        isContentRequiredPreRequisitesError() {
            return this.contentErrorMessageCode === 'PREREQUISITE_ERROR';
        },
        isContentNumberOfTriesExceeded() {
            return this.contentErrorMessageCode === 'EXCEEDED_NUMBER_OF_TRIES';
        },
        isContentTimeExceeded() {
            return this.contentErrorMessageCode === 'TIME_EXCEEDED';
        },
        isContentNotCompletedError() {
            return this.contentErrorMessageCode === 'NO_COMPLETED_SESSION';
        },

        isTimeExceedError() {
            return this.contentErrorMessageCode === 'TIME_EXCEEDED';
        },
        direction() {
            return this.contentData?.direction || 'RTL';
        },
        isCompleted() {
            return this.contentData?.is_completed || false;
        },
        isExam() {
            return this.contentData?.is_exam || false;
        },
        contentId(){
            return this.contentData?.id || 0;
        },
        isAlreadyStartedSolution(){
            return this.contentData?.is_already_started_solution || false;
        },
        navigation() {
            return {
                currentIndex: this.contentData.navigation?.current_index || '1',
                isValidNext: this.contentData.navigation?.is_valid_next || false,
                isValidPrevious: this.contentData.navigation?.is_valid_previous || false,
                nextType: this.contentData.navigation?.next_type || null,
                onClose: this.contentData.navigation?.on_close || null,
                onNext: this.contentData.navigation?.on_next || null,
                onPrevious: this.contentData.navigation?.on_previous || null,
                previousType: this.contentData.navigation?.previous_type || null,
                totalCount: this.contentData.navigation?.total_count || '1',
                courseSlug: this.contentData.navigation?.course_slug || null,
            };
        },
        nextText() {

            if (this.isValidNext) {
                return this.direction === 'LTR' ? this.nextTextEnglish : this.nextTextArabic;
            } else {
                return this.direction === 'LTR' ? this.nextOrPreviousTextEnglishDone : this.nextOrPreviousTextArabicDone;
            }
        },
        previousText() {

            if (this.isValidPrevious) {
                return this.direction === 'LTR' ? this.previousTextEnglish : this.previousTextArabic;
            } else {
                return this.direction === 'LTR' ? this.nextOrPreviousTextEnglishDone : this.nextOrPreviousTextArabicDone;
            }
        },
        startExamOrExerciseText() {
            if (this.isExam) {
                return this.direction === 'LTR' ? this.startExamEnglish : this.startExamArabic;
            } else {
                return this.direction === 'LTR' ? this.startExerciseEnglish : this.startExerciseArabic;
            }
        },
        alreadyStartedExamOrExerciseText() {
            if (this.isExam) {
                return this.direction === 'LTR' ? this.alreadyStartedExamEnglish : this.alreadyStartedExamArabic;
            } else {
                return this.direction === 'LTR' ? this.alreadyStartedExerciseEnglish : this.alreadyStartedExerciseArabic;
            }
        },
        exerciseOrExamStartText(){
            if (this.isAlreadyStartedSolution)
                return this.alreadyStartedExamOrExerciseText;
            else
                return this.startExamOrExerciseText;
        },
        numberOfQuestionsText() {
            if (this.direction === 'LTR')
                return this.numberOfQuestionsEnglish;
            else
                return this.numberOfQuestionsArabic;
        },
        durationInMinutesText() {
            if (this.direction === 'LTR')
                return this.durationInMinutesEnglish;
            else
                return this.durationInMinutesArabic;
        },
        difficultyLevelText() {
            if (this.direction === 'LTR')
                return this.difficultyLevelEnglish;
            else
                return this.difficultyLevelArabic;
        },
        PreviousAnswersText() {
            if (this.direction === 'LTR')
                return this.PreviousAnswersEnglish;
            else
                return this.PreviousAnswersArabic;
        },
        RetakeQuizText() {
            if (this.direction === 'LTR')
                return this.RetakeQuizEnglish;
            else
                return this.RetakeQuizArabic;
        },
        subscribeButtonText() {
            if (this.direction === 'LTR')
                return this.subscribeButtonEnglish;
            else
                return this.subscribeButtonArabic;
        },
        CheckAnswers() {
            if (this.direction === 'LTR')
                return this.CheckAnswersEnglish;
            else
                return this.CheckAnswersArabic;
        },
        TimeTakenToSolve() {
            if (this.direction === 'LTR')
                return this.TimeTakenToSolveEnglish;
            else
                return this.TimeTakenToSolveArabic;
        },
        YouDidNotAnswerText() {
            if (this.direction === 'LTR')
                return this.YouDidNotAnswerEnglish;
            else
                return this.YouDidNotAnswerArabic;  
        },
        currentIndex() {
            return this.navigation.currentIndex;
        },
        isValidNext() {
            return this.navigation.isValidNext;
        },
        isValidPrevious() {
            return this.navigation.isValidPrevious;
        },
        nextType() {
            return this.navigation.nextType;
        },
        onClose() {
            return this.navigation.onClose;
        },
        onNext() {
            return this.navigation.onNext;
        },
        onPrevious() {
            return this.navigation.onPrevious;
        },
        previousType() {
            return this.navigation.previousType;
        },
        totalCount() {
            return this.navigation.totalCount;
        },
        courseSlug() {
            return this.navigation.courseSlug;
        },
    },
    methods: {

        handleXIconClicked() {            
            routes.navigateToLesson(this.courseSlug, this.onClose)
        },
        handleNotEnrolledNavigation() {
            routes.navigateTo(routes.STUDY_CONTENT.key)
        },
        handleNavigation(onNavigate, navigationType) {
            if (navigationType === 'Lesson') {
                routes.navigateToLesson(this.courseSlug, onNavigate);
            } else if (navigationType === 'PaperSummary') {
                routes.navigateToPaperSummary(this.onClose, onNavigate);
            } else if (navigationType === 'Exercise') {
                routes.navigateToExercise(this.onClose, onNavigate);
            } else if (navigationType === 'Video') {
                routes.navigateToVideo(this.onClose, onNavigate);
            } else {
                routes.navigateToCourse(this.courseSlug);
            }
        },
        handleBackClicked() {
            this.handleNavigation(this.onPrevious, this.previousType);
        },
        handleNextClicked() {
            this.handleNavigation(this.onNext, this.nextType);
        },
    },

};