<template>
  <div class="flex flex-column min-h-screen items-center">
    <LoadingSpinner :is-loading="isLoading" />
    <div class="  flex flex-column flex-1 mx-auto w-full mb-[90px]">
      <div class="flex flex-col items-center mt-[2rem]">
        <div class="w-full my-4 ">
          <div class="max-w-2xl mx-auto px-[16px] md:px-[0]">
            <ExerciseHeader :dir="direction" :exerciseTitle="name" class="fixed-content flex flex-row justify-around"
              :textColor="isMobile ? 'black' : 'blue-custom'" @x-icon-clicked="handleXIconClickedExercise">
              <div class="flex flex-row gap-3 items-center align-center">
                <HeaderCounter :all="startExerciseNumberOfQuestions" :counter="currentQuestionIndex + 1"
                  v-if="!isMobile && !isExam" />
                <TimerBox :remainingTime="remainingMinutes" @timeup="handleTimeFinished" :width="isMobile ? 16 : 18"
                  :height="isMobile ? 16 : 18" />
              </div>
            </ExerciseHeader>
            <ProgressBar v-if="!isExam" :progress="getProgress" :dir="direction" class="mt-[40px]"
              :progress100="false" />

            <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
              <span v-for="(error, index) in errors" :key="index">
                {{ error }}
              </span>
            </div>
          </div>

          <div v-if="currentQuestionIndex < questions.length" class="exercise-content mx-auto mt-[5%]">
            <template v-if="isExam">
              <div v-for="(question, index) in questions" :key="question.id">
                <ExerciseQuestion :isExam="isExam" :direction="direction" :question="question" :index="index + 1" />
                <div class="mt-4 mb-8 max-w-2xl mx-auto px-[36px] md:px-[0]">
                  <AnswerChoices isExam="isExam" :dir="direction" v-for="answer in question.answers" :key="answer.id"
                    :answer="answer" :selectedAnswer="getSelectedAnswer(question.id)"
                    @select="selectAnswerExam(question.id, answer)" />
                </div>
              </div>
            </template>
            <template v-else>
              <ExerciseQuestion :isExam="isExam" :direction="direction" :question="currentQuestion" />
              <div class="mt-14 mb-20 md:mt-20 md:mb-36 max-w-2xl mx-auto px-[36px] md:px-[10px]">
                <AnswerChoices :dir="direction" v-for="answer in currentQuestion.answers" :key="answer.id"
                  :answer="answer" :isCorrect="isCorrect(currentQuestion.id)"
                  :isAnsweredAlreadyChecked="isAnswerChecked" :selectedAnswer="selectedAnswer"
                  @select="selectAnswer(currentQuestion.id, answer)" :disabled="isAnswerChecked" />
                <AnswerResult :dir="direction" v-if="isAnswerChecked" :isCorrect="isCorrect(currentQuestion.id)"
                  :modelAnswer="currentQuestion?.modelAnswer || {}"
                  :correctAnswer="getCorrectAnswer(currentQuestion.id)" :selectedAnswer="selectedAnswer"
                  :correctAnswerText="correctAnswerText" :wrongAnswerText="wrongAnswerText"
                  :explainAnswerText="explainAnswerText" :yourCorrectAnswerText="yourCorrectAnswerText" />
              </div>
            </template>
          </div>

        </div>
      </div>
    </div>

    <Blur ref="closeExercise" widthClass="w-10/12" mdWidthClass="md:w-5/12">
      <div class="blur-content">
        <div class="responsive-container ">
          <div
            class="image-container bg-gray-50 rounded-full border shadow-md h-14 w-14 flex items-center justify-center">
            <FlagIcon />
          </div>
          <div class="text-container text-base md:text-19px">{{ wantToCloseExamOrExerciseText }}</div>
          <div
            class="flex flex-row gap-5 justify-center items-center w-full text-gray-custom px-4 mt-16 buttons-holder">
            <ButtonCustom @click="handleCloseExamOrExercise" bgColor="bg-gray-font"
              className="text-white rounded-full p-4 text-base font-bold" height="35px" width="100%">
              {{ closeExamOrExerciseText }}
            </ButtonCustom>
            <ButtonCustom @click="resume" bgColor="bg-blue-custom"
              className="text-white rounded-full text-base font-bold p-4" height="35px" width="100%">
              {{ continueExamOrExerciseText }}
            </ButtonCustom>
          </div>
        </div>
      </div>
    </Blur>

    <Blur ref="finishExercise" widthClass="w-10/12" mdWidthClass="md:w-5/12">
      <div class="blur-content">
        <div class="relative responsive-container">
          <div
            class="image-container bg-gray-50 rounded-full border shadow-lg h-14 w-14 flex items-center justify-center">
            <FlagIcon />
          </div>
          <div class="text-container">{{ submitExamOrExerciseText }}</div>
          <div
            class="flex flex-row gap-5 justify-center items-center w-full text-gray-custom px-4 mt-16 buttons-holder">
            <ButtonCustom @click="returnToExercises" bgColor="bg-gray-font"
              className="text-white rounded-full p-4 text-base font-bold" height="35px">

              {{ submitDeclineExamOrExerciseText }}
            </ButtonCustom>
            <ButtonCustom @click="handleSubmitExercise" bgColor="bg-blue-custom"
              className="text-white rounded-full p-4 text-base font-bold" height="35px"
              :width="isMobile ? '100%' : '129px'">
              {{ submitConfirmExamOrExerciseText }}
            </ButtonCustom>
          </div>
        </div>
      </div>
    </Blur>


    <Blur ref="mustAnswerAllQuestions" widthClass="w-10/12" mdWidthClass="md:w-5/12">
      <div class="blur-content">
        <div class="relative responsive-container">
          <div
            class="image-container bg-gray-50 rounded-full border shadow-lg h-14 w-14 flex items-center justify-center">
            <FlagIcon />
          </div>
          <div class="text-container">{{ mustAnswerAllQuestionsText }}</div>
          <div
            class="flex flex-row gap-5 justify-center items-center w-full text-gray-custom px-4 mt-16 buttons-holder">
            <ButtonCustom @click="returnToExam" bgColor="bg-gray-font"
              className="text-white rounded-full p-4 text-base font-bold" height="35px">

              {{ submitDeclineExamOrExerciseText }}
            </ButtonCustom>
          </div>
        </div>
      </div>
    </Blur>

    <div class="w-full">
      <div v-if="!isExam">
        <FooterBlur v-if="!disableFooter && isMobile" class="p-4 fixed-footer">
          <div class="max-w-2xl mx-auto">
            <div class="grid grid-cols-4 items-center">
              <div class="flex gap-4 col-span-1">
                <ButtonCustom @click="previousQuestion" :disabled="currentQuestionIndex === 0"
                  className="rounded-xl flex items-center justify-center bg-light-gray-custom" width="35px"
                  height="35px">
                  <DirectionalArrowIconReverse color="black" />
                </ButtonCustom>
              </div>
              <ButtonCustom width="100%" height="35px" @click="handleButtonClick"
                :disabled="!$isNotEmpty(selectedAnswer)" :class="[
                  'rounded-xl w-6/12 h-10 flex items-center justify-center text-white md: font-bold-700 col-span-3',
                  $isNotEmpty(selectedAnswer) ? 'bg-blue-custom' : 'bg-gray-font cursor-not-allowed',
                ]">
                {{
                  isAnswerChecked ? (isLastQuestion ? submitExerciseText : nextQuestionText) :
                    checkAnswerText
                }}
              </ButtonCustom>
            </div>
          </div>
        </FooterBlur>

        <FooterBlur v-if="!disableFooter && !isMobile" class="p-4 fixed-footer">
          <div class="max-w-2xl mx-auto">
            <div class="flex justify-between items-center">
              <div class="flex gap-4">
                <ButtonCustom @click="previousQuestion" :disabled="currentQuestionIndex === 0"
                  className="rounded-xl flex items-center justify-center bg-light-gray-custom" width="49px"
                  height="49px">
                  <DirectionalArrowIconReverse color="black" />
                </ButtonCustom>
              </div>
              <ButtonCustom @click="handleButtonClick" :disabled="!$isNotEmpty(selectedAnswer)" :class="[
                'rounded-xl w-6/12 h-10 flex items-center justify-center text-white md: font-bold-700',
                $isNotEmpty(selectedAnswer) ? 'bg-blue-custom' : 'bg-gray-font cursor-not-allowed',
              ]" width="350px" height="49px">
                {{
                  isAnswerChecked ? (isLastQuestion ? submitExerciseText : nextQuestionText) :
                    checkAnswerText
                }}
              </ButtonCustom>
            </div>
          </div>
        </FooterBlur>
      </div>

      <div v-if="isExam">
        <FooterBlur v-if="!disableFooter" class="p-4 fixed-footer">
          <div class="w-full flex justify-center">
            <div class="flex items-center">
              <ButtonCustom bgColor="bg-blue-custom" :width="'300px'" @click="handleExamSubmit" :class="[
                'rounded-xl w-6/12 h-10 flex items-center justify-center md: font-bold-700 text-white'
              ]">
                {{ examSubmitButtonText }}
              </ButtonCustom>
            </div>
          </div>
        </FooterBlur>

      </div>
    </div>
  </div>
</template>

<script>
import FooterBlur from "@/components/General/FooterSection.vue";
import ButtonCustom from "@/components/General/ButtonCustom.vue";
import ExerciseHeader from "@/components/General/Header.vue";
import TimerBox from "@/components/General/TimerBox.vue";
import Blur from "@/components/General/Blur.vue";
import AnswerResult from "@/components/Exercise/Answers/AnswerResult.vue";
import AnswerChoices from "@/components/Exercise/Answers/AnswerChoices.vue";
import ProgressBar from "@/components/FormComponents/ProgressBar.vue";
import FlagIcon from "@/components/Icons/FlagIcon.vue";
import HeaderCounter from "@/components/General/HeaderCounter.vue";
import DirectionalArrowIconReverse from "@/components/Icons/DirectionalArrowIconReverse.vue";
import routeParamsMixin from "@/mixins/routeParams";
import exerciseContentDataMixin from '@/mixins/exerciseContentData';
import windowSizeMixin from "@/mixins/windowSize";
import ExerciseQuestion from "@/components/Exercise/Question.vue";
import cookieEncryption from "@/helpers/cookieEncryption";
import {
  submitExercise
} from "@/graphql/mutations/submitExercise/handlingCalls";
import routes from '@/router/routes.js';

export default {
  mixins: [routeParamsMixin, exerciseContentDataMixin, windowSizeMixin],

  name: "StartExercise",
  components: {
    ExerciseQuestion,
    FooterBlur,
    ButtonCustom,
    ExerciseHeader,
    TimerBox,
    Blur,
    AnswerResult,
    AnswerChoices,
    ProgressBar,
    FlagIcon,
    HeaderCounter,
    DirectionalArrowIconReverse,
  },
  props: {
    contentDataWrapperProp: {
      type: String,
      default: 'exerciseContent'
    },
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
    exerciseContent: {
      type: Object,
      required: true
    },
    exercise: {
      type: Object,
      required: true
    },
    exerciseError: {
      type: [Object, Array, String, null],
      required: true
    }
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    remainingMinutes() {
      return this.exerciseContent?.remaining_minutes || 0;
    },
    questions() {
      return this.exerciseContent?.questions || [];
    },
    startExerciseNumberOfQuestions() {
      return this.questions?.length || 0;
    },
    name() {
      return this.exercise?.name || '';
    },
    isLastQuestion() {
      return this.currentQuestionIndex === this.questions.length - 1;
    },
    getProgress() {
      const totalQuestions = this.questions.length;
      if (totalQuestions === 0) return 0;
      return ((this.currentQuestionIndex + 1) / totalQuestions) * 100;
    },
  },
  watch: {
    contentId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.initializeSelectedAnswers(newVal)

        }
      },
    },
    isTimeExceedError: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          const errorDataExerciseId = this.contentErrorMessageData?.exercise_id;
          if (errorDataExerciseId) {
            this.initializeSelectedAnswers(errorDataExerciseId)
            this.deleteExerciseCookie(errorDataExerciseId);
            this.submitExerciseAnswers(errorDataExerciseId);
          }

        }
      },
    },
  },
  data() {
    return {
      selectedAnswer: null,
      disableFooter: false,
      currentQuestionIndex: 0,
      selectedAnswers: {},
      isAnswerChecked: false,
      errors: [],
      isLoading: false,

    };
  },
  methods: {
    initializeSelectedAnswers(exerciseId) {
      const userAnswers = cookieEncryption.getCookie(this.$cookies, 'elkhetaExerciseAnswers') || {};
      if (userAnswers[exerciseId]) {
        this.selectedAnswers = userAnswers[exerciseId];
        this.updateSelectedAnswer();
      }
    },
    selectAnswer(questionId, answer) {
      if (this.isAnswerChecked || this.selectedAnswers[questionId]) return;

      this.selectedAnswer = answer;

    },

    selectAnswerExam(questionId, answer) {
      this.selectedAnswers[questionId] = answer;

      const exerciseId = this.contentId;
      const updatedAnswers = {
        ...this.selectedAnswers,
        [questionId]: answer,
      };

      cookieEncryption.updateCookie(this.$cookies, 'elkhetaExerciseAnswers', {
        [exerciseId]: updatedAnswers,
      }, 300);
    },
    getSelectedAnswer(questionId) {
      return this.selectedAnswers[questionId] || {};
    },
    isCorrect(questionId) {
      const selectedAnswer = this.getSelectedAnswer(questionId);
      return (selectedAnswer && selectedAnswer?.is_correct) || false;
    },
    getCorrectAnswer(questionId) {
      if (!Array.isArray(this.questions) || this.questions.length === 0) {
        return {};
      }

      const question = this.questions.find(q => q.id === questionId);
      if (!question) {
        return {};
      }

      if (!Array.isArray(question.answers) || question.answers.length === 0) {
        return {};
      }

      const correctAnswer = question.answers.find(answer => answer.is_correct === true);
      if (!correctAnswer) {
        return {};
      }

      return correctAnswer;
    },
    moveToNextQuestion() {
      if (this.isAnswerChecked && !this.isLastQuestion) {
        this.currentQuestionIndex++;
        this.updateSelectedAnswer();
      }
    },
    previousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.updateSelectedAnswer();
      }
    },
    updateSelectedAnswer() {
      const currentQuestionId = this.currentQuestion?.id || 0;
      this.selectedAnswer = this.getSelectedAnswer(currentQuestionId)
      this.isAnswerChecked = this.$isNotEmpty(this.selectedAnswer);
    },
    scrollToBottom() {
      this.$nextTick(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });
      });
    },
    handleButtonClick() {
      if (this.isAnswerChecked && !this.isLastQuestion) {
        this.moveToNextQuestion();
      } else if (this.isLastQuestion && this.isAnswerChecked) {
        this.finishExercisePrompt();
      } else {
        this.checkAnswer();
        this.scrollToBottom();
      }
    },
    handleExamSubmit() {

      const answeredQuestionsCount = Object.keys(this.selectedAnswers).length;
      const totalQuestionsCount = this.questions.length;

      if (answeredQuestionsCount < totalQuestionsCount) {
        this.mustAnswerAllQuestionsPrompt();
      } else {
        this.finishExercisePrompt();
      }
    },
    checkAnswer() {
      if (this.selectedAnswer) {
        const currentQuestionId = this.currentQuestion?.id || 0;

        this.selectedAnswers[currentQuestionId] = this.selectedAnswer;
        const exerciseId = this.contentId;
        const updatedAnswers = {
          ...this.selectedAnswers,
          [currentQuestionId]: this.selectedAnswer,
        };

        cookieEncryption.updateCookie(this.$cookies, 'elkhetaExerciseAnswers', {
          [exerciseId]: updatedAnswers,
        }, 300);
        this.isAnswerChecked = true;
      }
    },
    handleTimeFinished() {
      this.deleteExerciseCookie(this.contentId);
      this.submitExerciseAnswers(this.contentId);
    },
    handleSubmitExercise() {
      this.deleteExerciseCookie(this.contentId);
      this.submitExerciseAnswers(this.contentId, false);

    },
    async submitExerciseAnswers(exerciseId, isTimeFinished = true) {
      this.errors = [];
      try {
        const submitExerciseResponse = await this.$withLoading(() =>
          submitExercise(exerciseId, this.getAnswersForRequest(), isTimeFinished)
        );

        if (submitExerciseResponse.success === false) {
          this.errors = submitExerciseResponse.displayValidationMessages;
          this.resume();
        } else {
          Object.assign(this.$data, this.getRouteParamsData(this.$route.params));
          if (!isTimeFinished) {
            routes.navigateToExerciseResult(this.lessonSlug, this.exerciseSlug)
          } else {
            routes.navigateToExerciseResult(this.lessonSlug, this.exerciseSlug, {
              timeExceededError: true
            })
          }

        }
      } catch (error) {
        console.error("Error submitting exercise:", error);
      }
    },
    getAnswersForRequest() {
      return Object.values(this.selectedAnswers).map(answer => ({
        answer_id: answer.id,
      }));
    },
    deleteExerciseCookie(exerciseId) {
      cookieEncryption.removeFromCookie(this.$cookies, 'elkhetaExerciseAnswers', exerciseId);
    },
    handleXIconClickedExercise() {
      this.$refs.closeExercise.showBlur();
      this.disableFooter = true;
    },
    resume() {
      this.$refs.closeExercise.hideBlur();
      this.disableFooter = false;
    },
    returnToExercises() {
      this.$refs.finishExercise.hideBlur();
      this.disableFooter = false;
    },
    finishExercisePrompt() {
      this.$refs.finishExercise.showBlur();
      this.disableFooter = true;

    },
    mustAnswerAllQuestionsPrompt() {
      this.$refs.mustAnswerAllQuestions.showBlur();
      this.disableFooter = true;

    },
    returnToExam() {
      this.$refs.mustAnswerAllQuestions.hideBlur();
      this.disableFooter = false;
    },

  },
};
</script>

<style scoped>
.blur-content {
  text-wrap: nowrap;
}

.responsive-container {
  padding: 40px 0px;
}

.text-container {
  margin-top: 50px;

  @media (max-width: 425px) {
    margin-top: 60px;
  }
}

.buttons-holder {
  @media (max-width: 425px) {
    margin-top: 40px;
  }
}
</style>