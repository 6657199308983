<template>
  <LoadingSpinner :is-loading="isLoadingContactUs"/>

  <div :class="['w-full shadow-md h-[53px] md:h-[70px] z-[9999] custom-header', isFixed ? 'fixed' : 'absolute']"
       style="box-shadow: 0 4px 10px 0 #0000001A;">
    <div class="w-full flex h-full">
      <div v-if="showButtons"
           class="w-1/3 hidden md:relative md:flex md:justify-center md:items-center md:left-4 lg:left-0 lg:right-3 xl:right-14">
        <router-link :to="{ path: '/register' }" class="md:ml-[14.5%] lg:ml-[0%] mr-[4.375%]">
          <span
              class="relative w-[139px] h-[39px] rounded-[33px] bg-blue-custom flex items-center justify-center text-white font-bold text-[17px]">حساب
            جديد</span>
        </router-link>
        <router-link :to="{ path: '/login' }">
          <span
              class="w-[139px] h-[39px] rounded-[33px] bg-yellow-custom flex items-center justify-center text-black-custom font-bold text-[17px] ">تسجيل
            الدخول</span>
        </router-link>
      </div>
      <div v-if="!showButtons"
           class="relative w-1/3 h-full hidden md:relative md:flex md:justify-center md:items-center"></div>
      <div
          v-if="!isAuth"
          class="md:w-1/3 w-1/2 flex absolute md:bottom-[50%] top-[-35%] md:top-auto right-[10%] md:right-0 md:relative justify-end">
        <router-link class="w-full h-full flex relative md:top-[0] md:right-0 md:relative justify-end left-8 md:left-0"
                     :to="{ name: 'Home' }">
          <img class="w-[140px] md:h-auto md:w-[132px] cursor-pointer md:m-auto relative bottom-7 md:bottom-0"
               alt="logo" :src="require('@images/logo.png')">
        </router-link>
      </div>
      <div v-else
           class="md:w-1/3 w-1/2 flex absolute top-[19%] md:top-auto right-[6%] md:right-0 md:relative justify-end">
          <a :href="GOOGLE_PLAY_LINK" target="_blank"
             class=""
          >
            <div class="bg-blue-custom flex px-2 align-center justify-around w-[117px] h-[30px] rounded-full font-bold">
              <ClickIcon color="white" width="18px" height="18px" font-weight="700"/>
              <span class="text-12px text-white">حمل  التطبيق</span>
            </div>
          </a>
      </div>
      <div @click="handleContactUsClick"
           class="md:w-1/3 w-1/2 flex absolute top-[0%] h-full left-[5%] md:left-[0px] md:relative md:justify-end items-center text-center pt-[2.5px]">
        <div class="flex relative ms-10 md:m-10 right-7 md:right-0 bottom-[4px] md:bottom-0">
          <span
              class="h-[24px] md:h-[39px] w-[94px] cursor-pointer scale-[125%] md:scale-100 md:w-[199px] text-xs md:text-[17px] flex items-center justify-center bg-[#25D366] text-[#fff] rounded-[15px] md:rounded-[33px] ">
            <span
                class="rounded-full absolute right-[2px] md:right-[4px] bg-white w-[22px] md:w-[35px] h-[22px] md:h-[35px] p-[0.75px] md:p-[1.5px] overflow-hidden">
              <ContactUsIcon class="cursor-pointer rounded-full md:m-auto" :width="isMobile ? 20 : 32"
                             :height="isMobile ? 20 : 32"/>
            </span>
            <span class="me-5 md:me-2 font-roboto text-[12px] md:text-[17px] font-medium md:font-bold">تواصل معنا</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactUs from "@/mixins/contactUs";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import store from "@/store";
import ContactUsIcon from "@/components/Icons/ContactUsIcon.vue";
import windowSizeMixin from "@/mixins/windowSize";
import ClickIcon from "@/components/Icons/ClickIcon.vue";
import {GOOGLE_PLAY_LINK} from "@/helpers/config";

export default {
  components: {LoadingSpinner, ContactUsIcon, ClickIcon},
  mixins: [contactUs, windowSizeMixin],
  name: 'NavBar',
  props: {
    isFixed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      GOOGLE_PLAY_LINK
    };
  },
  computed: {
    isAuth() {
      return store.getters['Auth/isAuth'];
    },
    showButtons() {
      return (this.$route.name === 'Home' || this.$route.name === 'AboutUs' || this.$route.name === 'TermsOfUse') && !this.isAuth;
    }
  }
}
</script>

<style scoped>
.custom-header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  background-color: aliceblue;
  width: 100%;
  box-sizing: border-box;
}
</style>
